









































import { Component, Prop, Vue } from "vue-property-decorator";
import { FilterOptions, FilterObject, Subject, Industry } from "../models";
import { Multiselect } from "vue-multiselect";

import { mixins } from "vue-class-component";
import ContentComponent from "./ContentComponent";

@Component({
  components: { Multiselect },
})
export default class MentorFilter extends mixins(ContentComponent) {
  levelValue: string = "";
  subjectValues: Subject[] = [];
  industryValues: Industry[] = [];

  levelOptions: string[];
  subjectOptions: Subject[];
  industryOptions: Industry[];

  constructor() {
    super();
    this.levelOptions = this.filterOptions.levels;
    this.subjectOptions = this.filterOptions.subjects;
    this.industryOptions = this.filterOptions.industries;
    this.componentName = "mentor filter";
  }

  input() {
    const filter: FilterObject = {
      level: this.levelValue,
      subjects: this.subjectValues,
      industries: this.industryValues,
    };

    this.$emit("filterChanged", filter);
  }

  @Prop() private filterOptions!: FilterOptions;
}
